import { Datagrid, DateField, List, TextField, TextInput } from 'react-admin';

const Filters = [<TextInput key="1111" source="name" label="Search by name" />];

export const CustomerList = () => {
  return (
    <List filters={Filters}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <TextField label="Premium User" source="isPremium" />
        <DateField label="Blocked at" source="blockedAt" showTime />
      </Datagrid>
    </List>
  );
};
