import { httpClient } from '@shared/api';

export const axiosInterceptors = {
  setup: () => {
    httpClient.interceptors.request.use(
      async (config) => {
        const token = localStorage.getItem('accessToken');

        if (!token) {
          return config;
        }

        const headers = {
          ...config.headers,
          'Access-Control-Allow-Origin': '*',
          authorization: `Bearer ${token}`,
        };

        return { ...config, headers };
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    httpClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response) {
          throw error.response.data;
        }

        if (error.data) {
          throw error.data;
        }

        throw error;
      },
    );
  },
};
