import { Show, SimpleShowLayout, Tab, TabbedShowLayout, TextField } from 'react-admin';
import { AvatarField } from 'src/3_widgets/components/avatar-field/AvatarField';
import { BlockButton } from 'src/4_features/toggle-block';

export const CustomerShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="About user">
          <SimpleShowLayout>
            <AvatarField />
            <TextField label="ID" source="id" />
            <TextField label="Name" source="name" />
            <BlockButton />
          </SimpleShowLayout>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
