import { httpClient } from '@shared/api';
import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT, AuthActionType } from 'react-admin';

const authProvider = (type: AuthActionType, params: any): Promise<void> => {
  if (type === AUTH_LOGIN) {
    const { username: name, password } = params;
    // return httpClient.get('admin/me', {
    //   headers: {
    //     Authorization:
    //       'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE2MTY1YzUxLTQ1OTktNDY4NS1hMjQ3LThiNjg2MDk1ZWUzOCIsImV4cGlyYXRpb24iOiIyMDIyLTA5LTAyVDA5OjAyOjAzLjMxMVoiLCJ0b2tlblR5cGUiOiJhZG1pbiIsImlhdCI6MTY2MjEwODcyMywiZXhwIjoxNjYyMTA5MzIzfQ.uSyvIRIBqZZE7W2-qCqSr4g6RTWQ-hjCEl2u2joPNzE',
    //   },
    // });
    return httpClient
      .post('admin/signIn', { name, password })
      .then(({ data: { token } }) => {
        localStorage.setItem('accessToken', token);
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('accessToken');
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const {
      response: { status },
    } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('accessToken');
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    const token = localStorage.getItem('accessToken');
    if (token) {
      return Promise.resolve();
    }
    return Promise.reject();
  }

  return Promise.reject(new Error('Unknown method'));
};

export default authProvider;
