import { FC } from 'react';
import { ImageField, useShowContext } from 'react-admin';

export const AvatarField: FC = () => {
  const { record } = useShowContext();

  let imageUrl = '';
  if (record.avatar) {
    imageUrl = `https://initiate-public.s3.amazonaws.com/${record.avatar.image}`;
  }

  return (
    <>
      <ImageField source="url" />
      <div>
        <img src={imageUrl} title="Avatar" />
      </div>
    </>
  );
};
