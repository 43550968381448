import { Button } from '@mui/material';
import { FC } from 'react';
import { useNotify, useRefresh, useShowContext } from 'react-admin';

import { useGetUser, useToggleBlock } from './model';

export const BlockButton: FC = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const {
    record: { id },
    isFetching,
  } = useShowContext();

  const { toggle } = useToggleBlock({ id });
  const { dataUser, getUser } = useGetUser({ id });

  const handleClick = async () => {
    await toggle();
    await getUser();
    if (dataUser) {
      if (dataUser.blockedAt) {
        notify(`User unblocked`, { type: 'success' });
      } else {
        notify(`User blocked`, { type: 'error' });
      }
    }

    refresh();
  };

  return (
    <Button
      variant="contained"
      color={dataUser && dataUser.blockedAt ? 'warning' : 'success'}
      size="medium"
      onClick={handleClick}
      disabled={isFetching}
    >
      {dataUser && dataUser.blockedAt ? 'Unblock' : 'Block'}
    </Button>
  );
};
