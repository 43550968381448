import { API_BASE_URL } from '@shared/constants';

import { AdminPanelCustomerTagsApi, AdminPanelCustomersApi, DefaultApi } from './generated/api';
import { httpClient } from './http-client';

export const api = {
  DefaultApi: new DefaultApi(undefined, API_BASE_URL, httpClient),
  CustomersApi: new AdminPanelCustomersApi(undefined, API_BASE_URL, httpClient),
  TagsApi: new AdminPanelCustomerTagsApi(undefined, API_BASE_URL, httpClient),
};
