import { httpClient } from '@shared/api';
import { useEffect, useState } from 'react';
import { axiosInterceptors } from 'src/0_app/providers/axios/config';

type BlockProps = {
  id: string;
};

export const useToggleBlock = ({ id }: BlockProps) => {
  axiosInterceptors.setup();

  const toggle = async () => {
    await httpClient.post(`admin/customers/${id}/toggleBlock`);
  };

  return { toggle };
};

export const useGetUser = ({ id }: BlockProps) => {
  const [dataUser, setDataUser] = useState<any>();

  const getUser = async () => {
    const { data } = await httpClient.get(`admin/customers/${id}`);
    setDataUser(data);
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { getUser, dataUser };
};
