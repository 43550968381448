import { Datagrid, List, TextField } from 'react-admin';

export const ReportedCustomersList = () => {
  return (
    <List sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid rowClick="show" isRowSelectable={() => false} bulkActionButtons={false}>
        <TextField label="Name" source="customerName" />
        <TextField label="Reports counter" source="blockedCount" />
      </Datagrid>
    </List>
  );
};
