import { API_BASE_URL } from '@shared/constants';
import crudProvider from 'ra-data-nestjsx-crud';
import { fetchUtils } from 'react-admin';

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = localStorage.getItem('accessToken');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export default crudProvider(API_BASE_URL!, httpClient);
