import './index.scss';

import { FC } from 'react';
import { Admin, Resource } from 'react-admin';
import { CustomerList } from 'src/3_widgets/customers/list';
import { CustomerShow } from 'src/3_widgets/customers/show';
import { ReportedCustomersList } from 'src/3_widgets/reported-customers/list';
import { ReportedCustomerShow } from 'src/3_widgets/reported-customers/show';

import { authProvider, dataProvider } from './providers';

const App: FC = () => {
  return (
    <Admin authProvider={authProvider} dataProvider={dataProvider}>
      <Resource
        options={{ label: 'All customers' }}
        name="admin/customers"
        list={CustomerList}
        show={CustomerShow}
      />
      <Resource
        options={{ label: 'Reported customers' }}
        name="admin/customer/blocks"
        list={ReportedCustomersList}
        show={ReportedCustomerShow}
      />
    </Admin>
  );
};

export default App;
