/* tslint:disable */
/* eslint-disable */
/**
 * Initiate API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Admin
 */
export interface Admin {
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface AdminSignInDto
 */
export interface AdminSignInDto {
    /**
     * 
     * @type {string}
     * @memberof AdminSignInDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdminSignInDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CreateCutomerTagDto
 */
export interface CreateCutomerTagDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCutomerTagDto
     */
    'title': string;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'awsUserId': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'phone': string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'birthdate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'gender': CustomerGenderEnum;
    /**
     * 
     * @type {object}
     * @memberof Customer
     */
    'avatar'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Customer
     */
    'isPremium': boolean;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'lang': CustomerLangEnum;
    /**
     * 
     * @type {object}
     * @memberof Customer
     */
    'notificationsSettings': object;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'blockedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'deletedAt'?: string;
    /**
     * 
     * @type {Array<CustomerToCustomer>}
     * @memberof Customer
     */
    'relatedCustomers'?: Array<CustomerToCustomer>;
    /**
     * 
     * @type {Array<CustomerToCustomer>}
     * @memberof Customer
     */
    'meAsRelated'?: Array<CustomerToCustomer>;
    /**
     * 
     * @type {Array<CustomerFcm>}
     * @memberof Customer
     */
    'fcms': Array<CustomerFcm>;
    /**
     * 
     * @type {Array<Initiate>}
     * @memberof Customer
     */
    'initiates': Array<Initiate>;
    /**
     * 
     * @type {Array<Initiate>}
     * @memberof Customer
     */
    'meInitiated': Array<Initiate>;
    /**
     * 
     * @type {Array<CustomerTagCustomer>}
     * @memberof Customer
     */
    'customerTags': Array<CustomerTagCustomer>;
    /**
     * 
     * @type {Array<InAppPurchase>}
     * @memberof Customer
     */
    'inAppPurchases': Array<InAppPurchase>;
}

export const CustomerGenderEnum = {
    Male: 'male',
    Female: 'female',
    Other: 'other'
} as const;

export type CustomerGenderEnum = typeof CustomerGenderEnum[keyof typeof CustomerGenderEnum];
export const CustomerLangEnum = {
    En: 'en'
} as const;

export type CustomerLangEnum = typeof CustomerLangEnum[keyof typeof CustomerLangEnum];

/**
 * 
 * @export
 * @interface CustomerBlock
 */
export interface CustomerBlock {
    /**
     * 
     * @type {string}
     * @memberof CustomerBlock
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerBlock
     */
    'customerName': string;
    /**
     * 
     * @type {object}
     * @memberof CustomerBlock
     */
    'avatar': object;
    /**
     * 
     * @type {string}
     * @memberof CustomerBlock
     */
    'blockedCount': string;
}
/**
 * 
 * @export
 * @interface CustomerFcm
 */
export interface CustomerFcm {
    /**
     * 
     * @type {string}
     * @memberof CustomerFcm
     */
    'deviceId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFcm
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFcm
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerFcm
     */
    'customer': Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerFcm
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFcm
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerTag
 */
export interface CustomerTag {
    /**
     * 
     * @type {string}
     * @memberof CustomerTag
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTag
     */
    'title': string;
    /**
     * 
     * @type {Array<CustomerTagCustomer>}
     * @memberof CustomerTag
     */
    'customerTags': Array<CustomerTagCustomer>;
    /**
     * 
     * @type {string}
     * @memberof CustomerTag
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerTag
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface CustomerTagCustomer
 */
export interface CustomerTagCustomer {
    /**
     * 
     * @type {string}
     * @memberof CustomerTagCustomer
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerTagCustomer
     */
    'customer': Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerTagCustomer
     */
    'customerTagId': string;
    /**
     * 
     * @type {CustomerTag}
     * @memberof CustomerTagCustomer
     */
    'customerTag': CustomerTag;
}
/**
 * 
 * @export
 * @interface CustomerToCustomer
 */
export interface CustomerToCustomer {
    /**
     * 
     * @type {string}
     * @memberof CustomerToCustomer
     */
    'customerId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerToCustomer
     */
    'relatedCustomerId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerToCustomer
     */
    'isMuted': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerToCustomer
     */
    'isBlocked': boolean;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerToCustomer
     */
    'customer'?: Customer;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerToCustomer
     */
    'relatedCustomer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof CustomerToCustomer
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerToCustomer
     */
    'updatedAt': string;
}
/**
 * @type GetManyBaseAdminCustomerBlocksControllerCustomerBlock200Response
 * @export
 */
export type GetManyBaseAdminCustomerBlocksControllerCustomerBlock200Response = Array<CustomerBlock> | GetManyCustomerBlockResponseDto;

/**
 * @type GetManyBaseAdminCustomerTagsControllerCustomerTag200Response
 * @export
 */
export type GetManyBaseAdminCustomerTagsControllerCustomerTag200Response = Array<CustomerTag> | GetManyCustomerTagResponseDto;

/**
 * @type GetManyBaseAdminCustomersControllerCustomer200Response
 * @export
 */
export type GetManyBaseAdminCustomersControllerCustomer200Response = Array<Customer> | GetManyCustomerResponseDto;

/**
 * 
 * @export
 * @interface GetManyCustomerBlockResponseDto
 */
export interface GetManyCustomerBlockResponseDto {
    /**
     * 
     * @type {Array<CustomerBlock>}
     * @memberof GetManyCustomerBlockResponseDto
     */
    'data': Array<CustomerBlock>;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerBlockResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerBlockResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerBlockResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerBlockResponseDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface GetManyCustomerResponseDto
 */
export interface GetManyCustomerResponseDto {
    /**
     * 
     * @type {Array<Customer>}
     * @memberof GetManyCustomerResponseDto
     */
    'data': Array<Customer>;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerResponseDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface GetManyCustomerTagResponseDto
 */
export interface GetManyCustomerTagResponseDto {
    /**
     * 
     * @type {Array<CustomerTag>}
     * @memberof GetManyCustomerTagResponseDto
     */
    'data': Array<CustomerTag>;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerTagResponseDto
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerTagResponseDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerTagResponseDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof GetManyCustomerTagResponseDto
     */
    'pageCount': number;
}
/**
 * 
 * @export
 * @interface HealthControllerCheckApi200Response
 */
export interface HealthControllerCheckApi200Response {
    /**
     * 
     * @type {string}
     * @memberof HealthControllerCheckApi200Response
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi200Response
     */
    'info'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; } | null;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi200Response
     */
    'error'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; } | null;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi200Response
     */
    'details'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; };
}
/**
 * 
 * @export
 * @interface HealthControllerCheckApi200ResponseInfoValue
 */
export interface HealthControllerCheckApi200ResponseInfoValue {
    [key: string]: string | any;

    /**
     * 
     * @type {string}
     * @memberof HealthControllerCheckApi200ResponseInfoValue
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface HealthControllerCheckApi503Response
 */
export interface HealthControllerCheckApi503Response {
    /**
     * 
     * @type {string}
     * @memberof HealthControllerCheckApi503Response
     */
    'status'?: string;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi503Response
     */
    'info'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; } | null;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi503Response
     */
    'error'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; } | null;
    /**
     * 
     * @type {{ [key: string]: HealthControllerCheckApi200ResponseInfoValue; }}
     * @memberof HealthControllerCheckApi503Response
     */
    'details'?: { [key: string]: HealthControllerCheckApi200ResponseInfoValue; };
}
/**
 * 
 * @export
 * @interface InAppPurchase
 */
export interface InAppPurchase {
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'deletedAt': string;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'purchaseAt': string;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'expiredAt': string;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'canceledAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'validatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'platform': InAppPurchasePlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'originalTransactionId': string;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'latestReceipt': string;
    /**
     * 
     * @type {number}
     * @memberof InAppPurchase
     */
    'acknowledgementState': number;
    /**
     * 
     * @type {number}
     * @memberof InAppPurchase
     */
    'priceAmountRUB': number;
    /**
     * 
     * @type {string}
     * @memberof InAppPurchase
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof InAppPurchase
     */
    'customer': Customer;
}

export const InAppPurchasePlatformEnum = {
    Apple: 'apple',
    Google: 'google'
} as const;

export type InAppPurchasePlatformEnum = typeof InAppPurchasePlatformEnum[keyof typeof InAppPurchasePlatformEnum];

/**
 * 
 * @export
 * @interface Initiate
 */
export interface Initiate {
    /**
     * 
     * @type {string}
     * @memberof Initiate
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Initiate
     */
    'customerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof Initiate
     */
    'customer': Customer;
    /**
     * 
     * @type {string}
     * @memberof Initiate
     */
    'initiatedCustomerId': string;
    /**
     * 
     * @type {Customer}
     * @memberof Initiate
     */
    'initiatedCustomer': Customer;
    /**
     * 
     * @type {string}
     * @memberof Initiate
     */
    'type': InitiateTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Initiate
     */
    'viewed': boolean;
    /**
     * 
     * @type {Array<InitiateAction>}
     * @memberof Initiate
     */
    'actions': Array<InitiateAction>;
    /**
     * 
     * @type {string}
     * @memberof Initiate
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Initiate
     */
    'updatedAt': string;
}

export const InitiateTypeEnum = {
    Initiate: 'Initiate',
    SuperLike: 'SuperLike',
    TrialSuperLike: 'TrialSuperLike'
} as const;

export type InitiateTypeEnum = typeof InitiateTypeEnum[keyof typeof InitiateTypeEnum];

/**
 * 
 * @export
 * @interface InitiateAction
 */
export interface InitiateAction {
    /**
     * 
     * @type {string}
     * @memberof InitiateAction
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InitiateAction
     */
    'type': InitiateActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InitiateAction
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InitiateAction
     */
    'initiateId': string;
    /**
     * 
     * @type {Initiate}
     * @memberof InitiateAction
     */
    'initiate': Initiate;
}

export const InitiateActionTypeEnum = {
    Initiate: 'Initiate',
    SuperLike: 'SuperLike',
    TrialSuperLike: 'TrialSuperLike'
} as const;

export type InitiateActionTypeEnum = typeof InitiateActionTypeEnum[keyof typeof InitiateActionTypeEnum];

/**
 * 
 * @export
 * @interface UpdateCustomerTagDto
 */
export interface UpdateCustomerTagDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerTagDto
     */
    'title'?: string;
}

/**
 * AdminPanelCustomerBlocksApi - axios parameter creator
 * @export
 */
export const AdminPanelCustomerBlocksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve multiple CustomerBlocks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCustomerBlocksControllerCustomerBlock: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/customer/blocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single CustomerBlock
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCustomerBlocksControllerCustomerBlock: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminCustomerBlocksControllerCustomerBlock', 'id', id)
            const localVarPath = `/admin/customer/blocks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelCustomerBlocksApi - functional programming interface
 * @export
 */
export const AdminPanelCustomerBlocksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelCustomerBlocksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple CustomerBlocks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminCustomerBlocksControllerCustomerBlock(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyBaseAdminCustomerBlocksControllerCustomerBlock200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminCustomerBlocksControllerCustomerBlock(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single CustomerBlock
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminCustomerBlocksControllerCustomerBlock(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBlock>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminCustomerBlocksControllerCustomerBlock(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelCustomerBlocksApi - factory interface
 * @export
 */
export const AdminPanelCustomerBlocksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelCustomerBlocksApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve multiple CustomerBlocks
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCustomerBlocksControllerCustomerBlock(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyBaseAdminCustomerBlocksControllerCustomerBlock200Response> {
            return localVarFp.getManyBaseAdminCustomerBlocksControllerCustomerBlock(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single CustomerBlock
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCustomerBlocksControllerCustomerBlock(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<CustomerBlock> {
            return localVarFp.getOneBaseAdminCustomerBlocksControllerCustomerBlock(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelCustomerBlocksApi - object-oriented interface
 * @export
 * @class AdminPanelCustomerBlocksApi
 * @extends {BaseAPI}
 */
export class AdminPanelCustomerBlocksApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve multiple CustomerBlocks
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomerBlocksApi
     */
    public getManyBaseAdminCustomerBlocksControllerCustomerBlock(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCustomerBlocksApiFp(this.configuration).getManyBaseAdminCustomerBlocksControllerCustomerBlock(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single CustomerBlock
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomerBlocksApi
     */
    public getOneBaseAdminCustomerBlocksControllerCustomerBlock(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCustomerBlocksApiFp(this.configuration).getOneBaseAdminCustomerBlocksControllerCustomerBlock(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelCustomerTagsApi - axios parameter creator
 * @export
 */
export const AdminPanelCustomerTagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a single CustomerTag
         * @param {CreateCutomerTagDto} createCutomerTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminCustomerTagsControllerCustomerTag: async (createCutomerTagDto: CreateCutomerTagDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCutomerTagDto' is not null or undefined
            assertParamExists('createOneBaseAdminCustomerTagsControllerCustomerTag', 'createCutomerTagDto', createCutomerTagDto)
            const localVarPath = `/admin/customer-tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCutomerTagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single CustomerTag
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminCustomerTagsControllerCustomerTag: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOneBaseAdminCustomerTagsControllerCustomerTag', 'id', id)
            const localVarPath = `/admin/customer-tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple CustomerTags
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCustomerTagsControllerCustomerTag: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/customer-tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single CustomerTag
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCustomerTagsControllerCustomerTag: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminCustomerTagsControllerCustomerTag', 'id', id)
            const localVarPath = `/admin/customer-tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a single CustomerTag
         * @param {string} id 
         * @param {UpdateCustomerTagDto} updateCustomerTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminCustomerTagsControllerCustomerTag: async (id: string, updateCustomerTagDto: UpdateCustomerTagDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOneBaseAdminCustomerTagsControllerCustomerTag', 'id', id)
            // verify required parameter 'updateCustomerTagDto' is not null or undefined
            assertParamExists('updateOneBaseAdminCustomerTagsControllerCustomerTag', 'updateCustomerTagDto', updateCustomerTagDto)
            const localVarPath = `/admin/customer-tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerTagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelCustomerTagsApi - functional programming interface
 * @export
 */
export const AdminPanelCustomerTagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelCustomerTagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a single CustomerTag
         * @param {CreateCutomerTagDto} createCutomerTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOneBaseAdminCustomerTagsControllerCustomerTag(createCutomerTagDto: CreateCutomerTagDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOneBaseAdminCustomerTagsControllerCustomerTag(createCutomerTagDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single CustomerTag
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOneBaseAdminCustomerTagsControllerCustomerTag(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOneBaseAdminCustomerTagsControllerCustomerTag(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple CustomerTags
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminCustomerTagsControllerCustomerTag(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyBaseAdminCustomerTagsControllerCustomerTag200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminCustomerTagsControllerCustomerTag(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single CustomerTag
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminCustomerTagsControllerCustomerTag(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminCustomerTagsControllerCustomerTag(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a single CustomerTag
         * @param {string} id 
         * @param {UpdateCustomerTagDto} updateCustomerTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneBaseAdminCustomerTagsControllerCustomerTag(id: string, updateCustomerTagDto: UpdateCustomerTagDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneBaseAdminCustomerTagsControllerCustomerTag(id, updateCustomerTagDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelCustomerTagsApi - factory interface
 * @export
 */
export const AdminPanelCustomerTagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelCustomerTagsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a single CustomerTag
         * @param {CreateCutomerTagDto} createCutomerTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOneBaseAdminCustomerTagsControllerCustomerTag(createCutomerTagDto: CreateCutomerTagDto, options?: any): AxiosPromise<CustomerTag> {
            return localVarFp.createOneBaseAdminCustomerTagsControllerCustomerTag(createCutomerTagDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single CustomerTag
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneBaseAdminCustomerTagsControllerCustomerTag(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOneBaseAdminCustomerTagsControllerCustomerTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple CustomerTags
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCustomerTagsControllerCustomerTag(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyBaseAdminCustomerTagsControllerCustomerTag200Response> {
            return localVarFp.getManyBaseAdminCustomerTagsControllerCustomerTag(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single CustomerTag
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCustomerTagsControllerCustomerTag(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<CustomerTag> {
            return localVarFp.getOneBaseAdminCustomerTagsControllerCustomerTag(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a single CustomerTag
         * @param {string} id 
         * @param {UpdateCustomerTagDto} updateCustomerTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneBaseAdminCustomerTagsControllerCustomerTag(id: string, updateCustomerTagDto: UpdateCustomerTagDto, options?: any): AxiosPromise<CustomerTag> {
            return localVarFp.updateOneBaseAdminCustomerTagsControllerCustomerTag(id, updateCustomerTagDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelCustomerTagsApi - object-oriented interface
 * @export
 * @class AdminPanelCustomerTagsApi
 * @extends {BaseAPI}
 */
export class AdminPanelCustomerTagsApi extends BaseAPI {
    /**
     * 
     * @summary Create a single CustomerTag
     * @param {CreateCutomerTagDto} createCutomerTagDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomerTagsApi
     */
    public createOneBaseAdminCustomerTagsControllerCustomerTag(createCutomerTagDto: CreateCutomerTagDto, options?: AxiosRequestConfig) {
        return AdminPanelCustomerTagsApiFp(this.configuration).createOneBaseAdminCustomerTagsControllerCustomerTag(createCutomerTagDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single CustomerTag
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomerTagsApi
     */
    public deleteOneBaseAdminCustomerTagsControllerCustomerTag(id: string, options?: AxiosRequestConfig) {
        return AdminPanelCustomerTagsApiFp(this.configuration).deleteOneBaseAdminCustomerTagsControllerCustomerTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple CustomerTags
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomerTagsApi
     */
    public getManyBaseAdminCustomerTagsControllerCustomerTag(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCustomerTagsApiFp(this.configuration).getManyBaseAdminCustomerTagsControllerCustomerTag(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single CustomerTag
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomerTagsApi
     */
    public getOneBaseAdminCustomerTagsControllerCustomerTag(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCustomerTagsApiFp(this.configuration).getOneBaseAdminCustomerTagsControllerCustomerTag(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a single CustomerTag
     * @param {string} id 
     * @param {UpdateCustomerTagDto} updateCustomerTagDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomerTagsApi
     */
    public updateOneBaseAdminCustomerTagsControllerCustomerTag(id: string, updateCustomerTagDto: UpdateCustomerTagDto, options?: AxiosRequestConfig) {
        return AdminPanelCustomerTagsApiFp(this.configuration).updateOneBaseAdminCustomerTagsControllerCustomerTag(id, updateCustomerTagDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPanelCustomersApi - axios parameter creator
 * @export
 */
export const AdminPanelCustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomersControllerToggleBlock: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminCustomersControllerToggleBlock', 'id', id)
            const localVarPath = `/admin/customers/{id}/toggleBlock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve multiple Customers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCustomersControllerCustomer: async (fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (filter) {
                localVarQueryParameter['filter'] = filter;
            }

            if (or) {
                localVarQueryParameter['or'] = or;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a single Customer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCustomersControllerCustomer: async (id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOneBaseAdminCustomersControllerCustomer', 'id', id)
            const localVarPath = `/admin/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fields) {
                localVarQueryParameter['fields'] = fields.join(COLLECTION_FORMATS.csv);
            }

            if (join) {
                localVarQueryParameter['join'] = join;
            }

            if (cache !== undefined) {
                localVarQueryParameter['cache'] = cache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPanelCustomersApi - functional programming interface
 * @export
 */
export const AdminPanelCustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPanelCustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminCustomersControllerToggleBlock(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminCustomersControllerToggleBlock(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve multiple Customers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getManyBaseAdminCustomersControllerCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetManyBaseAdminCustomersControllerCustomer200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getManyBaseAdminCustomersControllerCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve a single Customer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOneBaseAdminCustomersControllerCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOneBaseAdminCustomersControllerCustomer(id, fields, join, cache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPanelCustomersApi - factory interface
 * @export
 */
export const AdminPanelCustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPanelCustomersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCustomersControllerToggleBlock(id: string, options?: any): AxiosPromise<Customer> {
            return localVarFp.adminCustomersControllerToggleBlock(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve multiple Customers
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getManyBaseAdminCustomersControllerCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: any): AxiosPromise<GetManyBaseAdminCustomersControllerCustomer200Response> {
            return localVarFp.getManyBaseAdminCustomersControllerCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a single Customer
         * @param {string} id 
         * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneBaseAdminCustomersControllerCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: any): AxiosPromise<Customer> {
            return localVarFp.getOneBaseAdminCustomersControllerCustomer(id, fields, join, cache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPanelCustomersApi - object-oriented interface
 * @export
 * @class AdminPanelCustomersApi
 * @extends {BaseAPI}
 */
export class AdminPanelCustomersApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomersApi
     */
    public adminCustomersControllerToggleBlock(id: string, options?: AxiosRequestConfig) {
        return AdminPanelCustomersApiFp(this.configuration).adminCustomersControllerToggleBlock(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve multiple Customers
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {string} [s] Adds search condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#search\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [filter] Adds filter condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#filter\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [or] Adds OR condition. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#or\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [sort] Adds sort by field. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#sort\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [limit] Limit amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#limit\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [offset] Offset amount of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#offset\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [page] Page portion of resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#page\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomersApi
     */
    public getManyBaseAdminCustomersControllerCustomer(fields?: Array<string>, s?: string, filter?: Array<string>, or?: Array<string>, sort?: Array<string>, join?: Array<string>, limit?: number, offset?: number, page?: number, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCustomersApiFp(this.configuration).getManyBaseAdminCustomersControllerCustomer(fields, s, filter, or, sort, join, limit, offset, page, cache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve a single Customer
     * @param {string} id 
     * @param {Array<string>} [fields] Selects resource fields. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#select\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {Array<string>} [join] Adds relational resources. &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#join\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {number} [cache] Reset cache (if was enabled). &lt;a href&#x3D;\&quot;https://github.com/nestjsx/crud/wiki/Requests#cache\&quot; target&#x3D;\&quot;_blank\&quot;&gt;Docs&lt;/a&gt;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPanelCustomersApi
     */
    public getOneBaseAdminCustomersControllerCustomer(id: string, fields?: Array<string>, join?: Array<string>, cache?: number, options?: AxiosRequestConfig) {
        return AdminPanelCustomersApiFp(this.configuration).getOneBaseAdminCustomersControllerCustomer(id, fields, join, cache, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerGetMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminSignInDto} adminSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerSignIn: async (adminSignInDto: AdminSignInDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminSignInDto' is not null or undefined
            assertParamExists('adminControllerSignIn', 'adminSignInDto', adminSignInDto)
            const localVarPath = `/admin/signIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminSignInDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminControllerGetMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Admin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerGetMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AdminSignInDto} adminSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminControllerSignIn(adminSignInDto: AdminSignInDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerSignIn(adminSignInDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appControllerGetHello(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appControllerGetHello(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerCheckApi(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthControllerCheckApi200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerCheckApi(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerGetMe(options?: any): AxiosPromise<Admin> {
            return localVarFp.adminControllerGetMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminSignInDto} adminSignInDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerSignIn(adminSignInDto: AdminSignInDto, options?: any): AxiosPromise<void> {
            return localVarFp.adminControllerSignIn(adminSignInDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appControllerGetHello(options?: any): AxiosPromise<string> {
            return localVarFp.appControllerGetHello(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerCheckApi(options?: any): AxiosPromise<HealthControllerCheckApi200Response> {
            return localVarFp.healthControllerCheckApi(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminControllerGetMe(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminControllerGetMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminSignInDto} adminSignInDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminControllerSignIn(adminSignInDto: AdminSignInDto, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminControllerSignIn(adminSignInDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public appControllerGetHello(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).appControllerGetHello(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerCheckApi(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthControllerCheckApi(options).then((request) => request(this.axios, this.basePath));
    }
}


